import  firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // to store images
import 'firebase/compat/firestore'; // database


// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyChg5-07aDS0IgKgn3ohMYUkKTZiJTBXKA",
  authDomain: "coderiomicro-firegram.firebaseapp.com",
  projectId: "coderiomicro-firegram",
  storageBucket: "coderiomicro-firegram.appspot.com",
  messagingSenderId: "726450587629",
  appId: "1:726450587629:web:ebb738a8c56948859e89e4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
// firebase.default.firestore.FieldValue.serverTimestamp()

export { projectStorage, projectFirestore, timestamp };